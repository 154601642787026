/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: 'icons';
  src: url('../fonts/icons.eot?24804043');
  src: url('../fonts/icons.eot?24804043#iefix') format('embedded-opentype'),
      url('../fonts/icons.woff?24804043') format('woff'),
      url('../fonts/icons.ttf?24804043') format('truetype'),
      url('../fonts/icons.svg?24804043#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  // speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-dribbble:before { content: '\e801'; }
.icon-instagram:before { content: '\e802'; }
.icon-behance:before { content: '\e803'; }
.icon-linkedin:before { content: '\e804'; }
.icon-facebook:before { content: '\e805'; }
.icon-twitter:before { content: '\e806'; }
.icon-gplus:before { content: '\e807'; }
.icon-pinterest:before { content: '\e808'; }
.icon-tumblr:before { content: '\e809'; }
.icon-down:before { content: '\e810'; }
.icon-left:before { content: '\e811'; }
.icon-right:before { content: '\e812'; }
.icon-up:before { content: '\e813'; }
.icon-left-small:before { content: '\e814'; }
.icon-right-small:before { content: '\e815'; }
.icon-up-small:before { content: '\e816'; }
.icon-down-small:before { content: '\e817'; }
.icon-down-thin:before { content: '\e818'; }
.icon-left-thin:before { content: '\e819'; }
.icon-right-thin:before { content: '\e820'; }
.icon-up-thin:before { content: '\e821'; }
.icon-down-arrow:before { content: '\e822'; }
.icon-left-arrow:before { content: '\e823'; }
.icon-right-arrow:before { content: '\e824'; }
.icon-up-arrow:before { content: '\e825'; }
.icon-menu:before { content: '\e826'; }
.icon-home:before { content: '\e827'; }
.icon-lock:before { content: '\e828'; }
.icon-phone:before { content: '\e829'; }
.icon-chat:before { content: '\e830'; }
.icon-mail:before { content: '\e831'; }
.icon-comment:before { content: '\e832'; }
.icon-at:before { content: '\e833'; }
.icon-star:before { content: '\e834'; }
.icon-search:before { content: '\e835'; }
.icon-zoom:before { content: '\e836'; }
.icon-cog:before { content: '\e837'; }
.icon-link:before { content: '\e838'; }
.icon-share:before { content: '\e839'; }
.icon-check:before { content: '\e840'; }
.icon-cancel:before { content: '\e841'; }
.icon-plus:before { content: '\e842'; }
.icon-minus:before { content: '\e843'; }
.icon-close:before { content: '\e844'; }
.icon-user:before { content: '\e845'; }
.icon-spinner:before { content: '\e846'; }
.icon-youtube:before {
  font-family: 'Material Icons';
  content: '\f06a';
  font-size: 24px;
}

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0,0,0,0.20);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}
a.social [class^="icon-"]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
}
a.social:hover, a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a.social.dribbble:hover, a.social.dribbble:active { background-color: #EA4C89; }
a.social.instagram:hover, a.social.instagram:active { background-color: #49789D; }
a.social.behance:hover, a.social.behance:active { background-color: #1769FF; }
a.social.facebook:hover, a.social.facebook:active { background-color: #3f5495; }
a.social.twitter:hover, a.social.twitter:active { background-color: #5d87c4; }
a.social.linkedin:hover, a.social.linkedin:active { background-color: #2575b2; }
a.social.gplus:hover, a.social.gplus:active { background-color: #cc4436; }
a.social.pinterest:hover, a.social.pinterest:active { background-color: #c2142f; }
a.social.tumblr:hover, a.social.tumblr:active { background-color: #529ECC; }
a.social.youtube:hover, a.social.youtube:active { background-color: #c2142f; }
