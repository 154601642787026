/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import "colors";
@import "icons";
@import "fonts";
@import "default";
@import "nav";
@import "buttons";
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
// .container {
//   max-width: 1350px;
// }
.container-fluid {
  &.but-fixed {
    // max-width: 1230px;
    max-width: 1350px;
    // &.wider {
    //   max-width: 1350px;
    // }
  }
}
.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background-color: $theme-primary;
  color: #ffffff;
  // border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  font-weight: 300;
  a,
  h3,
  h4 {
    color: #ffffff;
  }
  .social a {
    border: 0;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 100px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100%;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #780019;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\ea77";
        color: inherit;
        font-family: "Material Icons Round";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten(#780019, 7.5%);
      }
    }
    a.cart {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #780019;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\e8cc";
        color: inherit;
        font-family: "Material Icons Outlined";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten(#780019, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\e830";
        color: inherit;
        font-family: "icons";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
    #language {
      margin-top: 20px;
      float: right;
      clear: right;
      width: 170px;
    }
  }
}
/* ----------------------------------------------------------------------------
    Blogs styles
----------------------------------------------------------------------------- */
.post {
  padding: 20px 0;
  border-bottom: 1px solid #dee2e6;
  overflow: hidden;
  p:last-child {
    margin-bottom: 0;
  }
  .post-img {
    max-width: 200px;
  }
  .post-details {
    overflow: hidden;
  }
  .published-date {
    opacity: 0.7;
    font-size: 0.9rem;
  }
  .more-link {
    display: none;
    &:hover {
      margin-bottom: -1px;
    }
  }
}
.screen-reader-text {
  display: none;
}
figure {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  img {
    width: 100%;
    height: auto;
    padding: 0.25rem;
    border-radius: 0.25rem;
    border: 1px solid #dee2e6;
  }
  figcaption {
    margin-top: 1rem;
  }
  &.alignright {
    float: right;
    max-width: 40%;
    margin-left: 1.5rem;
  }
  &.alignleft {
    float: left;
    max-width: 40%;
    margin-right: 1.5rem;
  }
}

/* ----------------------------------------------------------------------------
    global styles
----------------------------------------------------------------------------- */
// .skiptranslate {
//   display: none;
// }
// #language {
//   float: right;
//   clear: right;
//   .skiptranslate {
//     display: block;
//     font-size: 0 !important;
//     select {
//       font-size: 1rem !important;
//     }
//     a {
//       display: none;
//     }
//   }
// }
#support-wrap {
  border-top: 1px solid #fff;
  background-color: $theme-primary;
  a {
    padding: 3px 0 3px 0;
    color: #fff;
    background-color: #780019;
    border: 0;
    &:first-child {
      border-right: 1px solid #fff;
    }
    &:hover {
      background-color: lighten(#780019, 5%);
    }
    &.login:before,
    &.cart:before {
      display: inline-block;
      margin-right: 5px;
      color: inherit;
      content: "\ea77";
      font-family: "Material Icons Round";
      font-weight: normal;
      font-size: 105%;
      vertical-align: top;
    }
    &.cart:before {
      content: "\e8cc";
      font-family: "Material Icons Outlined";
    }
  }
}
#banner-wrap {
  h1,
  h2,
  h3 {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  &.home {
    background-image: linear-gradient(-10deg, #2b9dee 0%, $theme-primary 100%);
  }
  &.pst {
    background-color: #e0ece4;
    h1,
    h2,
    h3 {
      text-shadow: none;
    }
    p {
      font-size: 1.2rem;
    }
  }
  &.faded-bg {
    h1,
    h2,
    h3 {
      text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.5);
    }
    position: relative;
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.8;
      background-color: #fff;
    }
  }
  &.pesticide {
    background: transparent
      url("/images/pesticide-applicator-ag-cover-image-sm.jpg") no-repeat 50%
      50% / cover;
  }
  &.affiliation {
    background: transparent url("/images/affiliations-header-bg-sm.jpg")
      no-repeat 50% 50% / cover;
  }
  &.government {
    background: transparent url("/images/government-solution-bg.jpg") no-repeat
      50% 80% / cover;
  }
  &.wps,
  &.business {
    background: transparent
      url("/images/agriculture-pesticide-background-sm.jpg") no-repeat 50% 50% /
      cover;
  }
  &.about-us,
  &.contact-us,
  &.work-with-us {
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      background: #fff;
      width: 100%;
      height: 50px;
      clip-path: polygon(50% 100%, 100% 0, 100% 100%, 0 100%, 0 0);
    }
    &:after {
      background: #fff;
      opacity: 0.7;
      height: 80px;
    }
  }
}
#phone-wrap {
  background-color: #1fbf66;
  color: #00532b;
  .open-phone {
    background-color: #015f32;
    color: #e3ffba;
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border: 22px solid transparent;
      border-left: 25px solid #1fbf66;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after {
      left: auto;
      right: 0;
      border: 22px solid transparent;
      border-right: 25px solid #1fbf66;
    }
  }
}
.bg-light-blue {
  background-color: #e5eef3;
}
.card {
  &.border-3 {
    border-width: 3px;
  }
  &.border-theme-primary {
    border-color: $theme-primary;
  }
  &.website {
    border-color: $theme-primary;
  }
  &.review {
    border-color: $theme-primary;
    .reviewer {
      text-align: center;
      &:after {
        margin: 10px auto 0 auto;
        display: block;
        width: 60px;
        height: 60px;
        content: "\201D";
        font-family: Georgia, "Times New Roman", Times, serif;
        font-size: 60px;
        line-height: 1.4;
        color: $theme-primary;
        font-weight: 700;
        text-align: center;
        border: 3px solid $theme-primary;
        border-radius: 50%;
      }
    }
  }
  &.about-us {
    border: 0;
    border-top: 5px solid lighten($theme-primary, 15%);
    background-color: $theme-primary;
    .img-fluid {
      max-width: 220px;
    }
  }
  &.contact-us {
    border-color: $theme-primary;
    .img-fluid {
      max-width: 220px;
    }
    a.phone-link {
      border-top: 3px solid $theme-primary;
      color: $theme-primary;
    }
  }
  &.course-type {
    border-color: $theme-primary;
    margin-top: 50px;
    .icon {
      margin-top: -43px;
      margin-left: 1.25rem;
      padding: 15px;
      width: 86px;
      height: 86px;
      border: 3px solid $theme-primary;
      color: #444;
      background-color: #fff;
      border-radius: 0.3rem;
      text-align: center;
    }
  }
}
.staff {
  padding: 0 0.75rem;
  .img-fluid {
    max-width: 150px;
  }
}
.line-seperator {
  position: relative;
  margin-bottom: 2.75rem;
  background: $theme-primary;
  width: 100%;
  height: 20px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.boxed-hover {
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2);
  }
}
#other-solutions-wrap,
#wps-wrap,
.bg-light-green {
  background-color: #e5efe8;
}
.card-header-wrapper {
  position: relative;
  .title-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}
.border-bottom-3 {
  border-bottom: 3px solid $theme-secondary;
}
.form-msg {
  p:last-child {
    margin-bottom: 0;
  }
}

.notice-wrap {
  background-color: #dd3318;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 15px;
  position: relative;
  text-align: center;
  z-index: 5000000;
}
.notice-wrap p {
  color: #e8f0fe;
  margin: 0 16px;
}
.closeBtn {
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
  color: #fff;
  padding: 0 5px;
  font-weight: 400;
}
.closeBtn:hover {
  color: #ccc;
}

.card-cta {
  a {
    min-width: 235px;
    padding: 0.5rem 1rem;
    width: auto;
    height: auto;
    background-color: $theme-primary;
    border-color: $theme-primary;
    border-radius: 0.3rem;
    font-family: var(--font-family);
    line-height: 1.5;
  }
}
.why-choose {
  background: linear-gradient(13deg, #9e4913 55%, #c05c1e 55%);
}
.testimonial {
  border: 3px solid $theme-secondary;
  border-radius: 5px;
  .card-body {
    p {
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
    p span {
      background: #fff;
      line-height: 0.5rem;
      padding: 2.5rem 0.4rem 0.1rem;
      position: absolute;
      top: -30px;
      border: $theme-secondary 3px solid;
      border-radius: 50%;
      &::after {
        content: "\201D";
        color: $theme-primary;
        font-family: Georgia, "Open Sans", "Trebuchet MS", Verdana, Arial,
          Helvetica, sans-serif;
        font-size: 5rem;
        font-weight: 700;
        position: relative;
        vertical-align: middle;
      }
    }
  }
  .card-footer p {
    font-weight: 600;
    font-size: 1.1rem;
  }
}
.mobile-phone {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #008000;
  color: #fff !important;
  text-align: center;
  left: 20px;
  bottom: 20px;
  border-radius: 50px;
  .icon-phone {
    font-size: 1.75rem;
    line-height: 48px;
    &:before {
      transform: rotate(-90deg);
    }
  }
}
.phone-callout {
  background-color: $theme-secondary;
  p {
    font-size: 1.2rem;
  }
  a {
    border-bottom: 1px dotted #fff;
  }
}
#icons-wrap {
  border-bottom: 2px solid #ccc;
  .icon-item {
    border-bottom: 2px dotted #aaa;
  }
  .icon-item:last-child {
    border-bottom: 0;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    max-width: 150px;
  }
  #header-wrap {
    // border-top: 3px solid #780019;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #phone-wrap {
    font-size: 1.2rem;
  }
  .closeBtn {
    right: 20px;
  }
  #icons-wrap {
    border-bottom: 0;
    .icon-item {
      border-bottom: 0;
      &:after {
        border-right: 2px dotted #aaa;
        content: "";
        position: absolute;
        right: 0;
        top: 15%;
        bottom: 15%;
        width: 2px;
      }
      &:last-child {
        &:after {
          border-right: 0;
        }
      }
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    max-width: 200px;
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #phone-wrap {
    font-size: 1.3rem;
    .open-phone {
      font-size: 1.4rem;
      border-radius: 20px;
      &::before {
        left: 17px;
      }
      &::after {
        right: 17px;
      }
    }
    .open-blurb {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 28px;
        background-color: #1fbf66;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
    }
    .open-days {
      &::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 28px;
        background-color: #1fbf66;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  #banner-wrap {
    &.pesticide {
      background: transparent
        url("/images/pesticide-applicator-ag-cover-image.jpg") no-repeat 50% 50% /
        cover;
    }
    &.affiliation {
      background: transparent url("/images/affiliations-header-bg.jpg")
        no-repeat 50% 50% / cover;
    }
    &.wps,
    &.business {
      background: transparent
        url("/images/agriculture-pesticide-background.jpg") no-repeat 50% 50% /
        cover;
    }
    &.pst {
      p {
        font-size: 1.4rem;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #icons-wrap {
    border-bottom: 0;
    .icon-item {
      border-bottom: 0;
      border-right: 2px dotted #aaa;
      &:after {
        border-right: 0;
      }
    }
    .icon-item:last-child {
      border-right: 0;
    }
  }
}
