@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #006BB0 !important; }

.text-theme-secondary {
  color: #00611C !important; }

.text-headline-primary {
  color: #00611C !important; }

.text-headline-secondary {
  color: #006BB0 !important; }

.text-theme-danger {
  color: #880303 !important; }

/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: 'icons';
  src: url("../fonts/icons.eot?24804043");
  src: url("../fonts/icons.eot?24804043#iefix") format("embedded-opentype"), url("../fonts/icons.woff?24804043") format("woff"), url("../fonts/icons.ttf?24804043") format("truetype"), url("../fonts/icons.svg?24804043#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-dribbble:before {
  content: '\e801'; }

.icon-instagram:before {
  content: '\e802'; }

.icon-behance:before {
  content: '\e803'; }

.icon-linkedin:before {
  content: '\e804'; }

.icon-facebook:before {
  content: '\e805'; }

.icon-twitter:before {
  content: '\e806'; }

.icon-gplus:before {
  content: '\e807'; }

.icon-pinterest:before {
  content: '\e808'; }

.icon-tumblr:before {
  content: '\e809'; }

.icon-down:before {
  content: '\e810'; }

.icon-left:before {
  content: '\e811'; }

.icon-right:before {
  content: '\e812'; }

.icon-up:before {
  content: '\e813'; }

.icon-left-small:before {
  content: '\e814'; }

.icon-right-small:before {
  content: '\e815'; }

.icon-up-small:before {
  content: '\e816'; }

.icon-down-small:before {
  content: '\e817'; }

.icon-down-thin:before {
  content: '\e818'; }

.icon-left-thin:before {
  content: '\e819'; }

.icon-right-thin:before {
  content: '\e820'; }

.icon-up-thin:before {
  content: '\e821'; }

.icon-down-arrow:before {
  content: '\e822'; }

.icon-left-arrow:before {
  content: '\e823'; }

.icon-right-arrow:before {
  content: '\e824'; }

.icon-up-arrow:before {
  content: '\e825'; }

.icon-menu:before {
  content: '\e826'; }

.icon-home:before {
  content: '\e827'; }

.icon-lock:before {
  content: '\e828'; }

.icon-phone:before {
  content: '\e829'; }

.icon-chat:before {
  content: '\e830'; }

.icon-mail:before {
  content: '\e831'; }

.icon-comment:before {
  content: '\e832'; }

.icon-at:before {
  content: '\e833'; }

.icon-star:before {
  content: '\e834'; }

.icon-search:before {
  content: '\e835'; }

.icon-zoom:before {
  content: '\e836'; }

.icon-cog:before {
  content: '\e837'; }

.icon-link:before {
  content: '\e838'; }

.icon-share:before {
  content: '\e839'; }

.icon-check:before {
  content: '\e840'; }

.icon-cancel:before {
  content: '\e841'; }

.icon-plus:before {
  content: '\e842'; }

.icon-minus:before {
  content: '\e843'; }

.icon-close:before {
  content: '\e844'; }

.icon-user:before {
  content: '\e845'; }

.icon-spinner:before {
  content: '\e846'; }

.icon-youtube:before {
  font-family: 'Material Icons';
  content: '\f06a';
  font-size: 24px; }

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s; }

a.social [class^="icon-"]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px; }

a.social:hover, a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a.social.dribbble:hover, a.social.dribbble:active {
  background-color: #EA4C89; }

a.social.instagram:hover, a.social.instagram:active {
  background-color: #49789D; }

a.social.behance:hover, a.social.behance:active {
  background-color: #1769FF; }

a.social.facebook:hover, a.social.facebook:active {
  background-color: #3f5495; }

a.social.twitter:hover, a.social.twitter:active {
  background-color: #5d87c4; }

a.social.linkedin:hover, a.social.linkedin:active {
  background-color: #2575b2; }

a.social.gplus:hover, a.social.gplus:active {
  background-color: #cc4436; }

a.social.pinterest:hover, a.social.pinterest:active {
  background-color: #c2142f; }

a.social.tumblr:hover, a.social.tumblr:active {
  background-color: #529ECC; }

a.social.youtube:hover, a.social.youtube:active {
  background-color: #c2142f; }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* fjalla-one-regular - latin */
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg");
  /* Legacy iOS */ }

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg");
  /* Legacy iOS */ }

/* roboto-slab-600 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg");
  /* Legacy iOS */ }

/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg");
  /* Legacy iOS */ }

/* material icons */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIcons-Regular.woff2") format("woff2"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale; }

/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf") format("woff2"); }

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale; }

/* fallback */
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsRound-Regular.otf") format("woff2"); }

.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale; }

/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth; } }

[id] {
  scroll-margin-top: 1rem; }

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  font-family: var(--font-family);
  line-height: 1.4;
  color: #00611C; }

h1,
.h1 {
  font-size: 1.602em; }

h2,
.h2 {
  font-size: 1.424em; }

h3,
.h3 {
  font-size: 1.266em; }

h4,
.h4 {
  font-size: 1.125em; }

h5,
.h5 {
  font-size: 1em; }

@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  h1,
  .h1 {
    font-size: 1.8em; }
  h2,
  .h2 {
    font-size: 1.55em; }
  h3,
  .h3 {
    font-size: 1.32em; }
  h4,
  .h4 {
    font-size: 1.125em; }
  h5,
  .h5 {
    font-size: 1em; } }

@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  h1,
  .h1 {
    font-size: 2.074em; }
  h2,
  .h2 {
    font-size: 1.728em; }
  h3,
  .h3 {
    font-size: 1.44em; }
  h4,
  .h4 {
    font-size: 1.2em; }
  h5,
  .h5 {
    font-size: 1.1em; } }

@media (min-width: 75em) {
  /* Extra large devices (large desktops, 1200px and up) */
  h1,
  .h1 {
    font-size: 2.441em; }
  h2,
  .h2 {
    font-size: 1.953em; }
  h3,
  .h3 {
    font-size: 1.563em; }
  h4,
  .h4 {
    font-size: 1.25em; }
  h5,
  .h5 {
    font-size: 1.1em; } }

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit; }

a {
  color: #316ce8;
  text-decoration: none;
  outline: none !important; }

a:hover,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #1752cf; }

a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0; }

a[href^="tel:"] {
  color: inherit;
  border: 0; }

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em; }

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both; }

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word; }

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important; }

.display-none {
  display: none !important; }

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
body.modal-open {
  overflow: visible;
  padding: 0 !important; }

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }
  .modal-header .close {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-top: -0.7rem; }

.modal-body .close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box; }
  .dropdown-list span.dropdown {
    display: block;
    margin: 0;
    padding: 0 10px;
    width: auto;
    height: 40px;
    line-height: 38px;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #a1c4e5;
    cursor: pointer;
    overflow: hidden; }
    .dropdown-list span.dropdown.is-active {
      border-radius: 3px 3px 0 0; }
    .dropdown-list span.dropdown:hover, .dropdown-list span.dropdown:active {
      background: #f1f1f1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    .dropdown-list span.dropdown:after {
      float: right;
      margin-top: 0;
      margin-left: 10px;
      width: 15px;
      height: 100%;
      content: "\e817";
      color: ineherit;
      font-family: "icons";
      text-align: center;
      font-size: inherit;
      vertical-align: top; }
    .dropdown-list span.dropdown.is-active:after {
      content: ""; }
  .dropdown-list a:hover {
    text-decoration: none;
    border-bottom: 0; }
  .dropdown-list .drop {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    float: left;
    width: 100%;
    max-height: 0;
    position: absolute;
    background: #f9f9f9;
    top: 40px;
    border-radius: 0 0 3px 3px;
    z-index: 10;
    overflow-y: auto;
    opacity: 0;
    transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1); }
    .dropdown-list .drop li {
      float: none;
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      border: 0;
      height: auto;
      border-top: 1px dotted #ccc; }
      .dropdown-list .drop li:first-child {
        border-top: 0; }
      .dropdown-list .drop li span.submenu.level-2 {
        display: block;
        margin: 0;
        padding: 6px 30px 6px 17px;
        position: relative;
        font-weight: 600;
        text-align: left; }
        .dropdown-list .drop li span.submenu.level-2:after {
          display: block;
          width: 20px;
          content: "\e817";
          color: inherit;
          font-family: "icons";
          text-align: center;
          font-size: 105%;
          position: absolute;
          right: 10px;
          top: 5px; }
        .dropdown-list .drop li span.submenu.level-2.is-active:after {
          content: "\e816"; }
        .dropdown-list .drop li span.submenu.level-2.is-active, .dropdown-list .drop li span.submenu.level-2:hover {
          background-color: #f1f1f1;
          cursor: pointer; }
        .dropdown-list .drop li span.submenu.level-2.is-active + ul.submenu {
          max-height: 200px;
          background-color: #ffffff;
          transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; }
      .dropdown-list .drop li ul.submenu {
        padding-left: 0;
        max-height: 0;
        overflow-y: auto;
        transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1); }
        .dropdown-list .drop li ul.submenu li:first-child {
          border-top: 1px dotted #ccc; }
        .dropdown-list .drop li ul.submenu li a {
          padding-left: 33px;
          font-weight: normal; }
        .dropdown-list .drop li ul.submenu li a:hover {
          background-color: #fbfbfb;
          padding-left: 35px; }
      .dropdown-list .drop li a {
        float: none;
        display: block;
        margin: 0;
        padding: 6px 10px 6px 25px;
        height: auto;
        width: auto;
        text-transform: none;
        font-weight: 600;
        color: inherit;
        text-align: left;
        border-radius: 0;
        border: 0;
        transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1); }
        .dropdown-list .drop li a:hover, .dropdown-list .drop li a:active {
          background: #f1f1f1; }
        .dropdown-list .drop li a:before {
          display: inline-block;
          margin-left: -15px;
          margin-right: 5px;
          width: 10px;
          height: inherit;
          content: "\00BB";
          color: inherit;
          font-family: "icons";
          text-align: center;
          font-size: inherit;
          vertical-align: top; }
  .dropdown-list span.is-active + .drop {
    max-height: 350px;
    border: 1px solid #a1c4e5;
    border-top: 0;
    opacity: 1;
    transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out; }
  .dropdown-list.dropdown-cta {
    display: inline-block;
    width: auto; }
    .dropdown-list.dropdown-cta span.dropdown {
      padding: 0.5rem 1rem;
      display: inline-block;
      width: auto;
      height: auto;
      background-color: #00611C;
      border-color: #00611C;
      border-radius: 0.3rem;
      font-family: var(--font-family);
      line-height: 1.5;
      text-align: left; }
      .dropdown-list.dropdown-cta span.dropdown:after {
        font-weight: 400; }
      .dropdown-list.dropdown-cta span.dropdown:hover {
        background-color: #007b23;
        border-color: #00942b; }
    .dropdown-list.dropdown-cta .drop {
      top: calc(100% - 3px);
      max-width: 260px;
      border-radius: 0 0 3px 3px; }
    .dropdown-list.dropdown-cta .drop-lp {
      max-width: 235px; }
      .dropdown-list.dropdown-cta .drop-lp li span {
        text-align: left; }
    .dropdown-list.dropdown-cta span.is-active + .drop {
      max-height: 450px;
      border: 1px solid #00611C; }

ul,
ol {
  padding-left: 30px; }

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em; }

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit; }

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative; }

ul.carrot li {
  padding-left: 0.9em; }

ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: inherit;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0; }

ul.check > li:before {
  content: "\e840";
  font-family: "icons"; }

ul.star > li:before {
  content: "\e834";
  font-family: "icons"; }

ul.carrot > li:before {
  content: "\00BB";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em; }

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem; }

.accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
  border-left: 1px dotted #ccc;
  border-right: 1px dotted #ccc;
  overflow: hidden; }
  .accordion:first-child {
    border-top: 1px dotted #ccc; }
  .accordion .accordion-toggle {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: calc(1em + 1rem);
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    color: inherit;
    overflow: hidden;
    transition: all ease-in-out 0.1s; }
    .accordion .accordion-toggle:before {
      float: right;
      color: inherit;
      content: "\e817";
      font-family: "icons";
      font-size: 1em;
      font-weight: normal !important;
      height: inherit;
      width: 1em;
      margin-right: -1em;
      text-align: right; }
    .accordion .accordion-toggle:hover {
      background-color: rgba(0, 0, 0, 0.02); }
  .accordion .accordion-content {
    padding: 0 1rem;
    height: 0;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s ease-in-out, height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
    overflow: hidden; }
    .accordion .accordion-content .accordion {
      border-left: 0;
      border-right: 0; }
      .accordion .accordion-content .accordion .accordion-toggle {
        padding-left: 0.75rem;
        padding-right: calc(1em + 0.75rem); }
      .accordion .accordion-content .accordion.is-open > .accordion-toggle {
        background-color: rgba(0, 0, 0, 0.02);
        color: #444 !important;
        margin-bottom: 0.75rem; }
  .accordion.is-open > .accordion-content {
    height: auto;
    opacity: 1;
    transform: scaleY(1);
    transition: transform 0.1s ease-in-out, height 0.2s ease-in-out, opacity 0.2s ease-in-out; }
  .accordion.is-open > .accordion-toggle {
    background-color: rgba(0, 0, 0, 0.02);
    background-color: #006BB0;
    color: #fff !important;
    margin-bottom: 1rem; }
    .accordion.is-open > .accordion-toggle:before {
      content: "\e816"; }

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600; }

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }
  .modal-header h1,
  .modal-header h2,
  .modal-header h3,
  .modal-header h4,
  .modal-header h5 {
    margin-top: 0;
    font-weight: 400; }

input[type="number"] {
  appearance: textfield; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

::placeholder {
  color: #cccccc !important; }

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16; }

.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16; }

.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-control.custom-checkbox {
  padding-left: 1.75rem; }
  .custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
    left: -1.75rem;
    width: 1.2rem;
    height: 1.2rem; }
  .custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    border-color: #316ce8;
    background-color: #316ce8; }

.custom-control.custom-radio {
  padding-left: 1.75rem; }
  .custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
    left: -1.75rem;
    width: 1.2rem;
    height: 1.2rem; }
  .custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
    border-color: #316ce8;
    background-color: #316ce8; }

.form-control.is-invalid {
  border-color: #fb4f16; }

.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none; }

.custom-select.is-invalid {
  border-color: #fb4f16; }

.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"); }

.table {
  word-wrap: normal;
  word-break: normal; }
  .table.align-middle th,
  .table.align-middle td {
    vertical-align: middle; }
  .table.table-sm td,
  .table.table-sm th {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }

.list-group-item {
  margin-top: 0;
  margin-bottom: 0; }

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px; } }

/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  color: #555;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  text-shadow: 0 0 40px #333;
  vertical-align: middle;
  color: transparent; }

.price.static {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5); }

.price.shown {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s; }

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
  text-shadow: 0 0 25px #fff;
  color: transparent; }

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
  text-shadow: 0 0 25px #fff;
  color: transparent; }

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2); }

.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s; }

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top; }

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block; }

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle; }

.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px; }

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none; }

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2); }

.price span.dollars[data-pid]:after,
.price span.dollars-fees[data-pid]:after,
.price span.cents[data-pid]:after,
.price span.cents-fees[data-pid]:after,
.price span.dollars[data-pkgid]:after,
.price span.cents[data-pkgid]:after {
  display: inline-block;
  content: "##"; }

.price.shown span.dollars[data-pid]:after,
.price.shown span.dollars-fees[data-pid]:after,
.price.shown span.cents[data-pid]:after,
.price.shown span.cents-fees[data-pid]:after,
.price.shown span.dollars[data-pkgid]:after,
.price.shown span.cents[data-pkgid]:after {
  display: none; }

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0; }
  .lga-item.lga-replaced {
    display: inline;
    opacity: 1; }

/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background: #00548A; }

#menutoggle {
  display: block;
  margin: 0;
  padding: 0 22px;
  width: auto;
  color: #fff;
  background: #00548A;
  height: 45px;
  font-size: 18px;
  line-height: 46px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 4px 4px 0 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  #menutoggle span {
    display: none; }
  #menutoggle::before {
    display: inline-block;
    margin-left: 0;
    width: 18px;
    height: 45px;
    line-height: 48px;
    content: "\e826";
    color: inherit;
    font-family: 'icons';
    text-align: center;
    font-size: 21px;
    vertical-align: top; }
  #menutoggle.is-active:before {
    content: "\e844"; }
  #menutoggle:hover, #menutoggle:active, #menutoggle.is-active {
    background-color: #006bb0;
    border-radius: 4px 4px 0 0; }
  #menutoggle.is-active + ul {
    max-height: 1000px;
    opacity: 1;
    transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out; }

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
  border-top: 1px solid #00548A; }
  #nav ul {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    opacity: 0;
    max-height: 0;
    background-color: #00548A;
    overflow: hidden;
    transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
    border-radius: 0 0 4px 4px;
    top: 1px;
    border: 0;
    position: relative; }
    #nav ul.submenu {
      margin: 0;
      padding: 0;
      width: 100%;
      max-height: 0;
      background-color: #005d99;
      opacity: 1;
      position: relative;
      top: 0;
      transition: max-height 0.15s linear 0s; }
      #nav ul.submenu a {
        padding-left: 40px;
        background: none; }
        #nav ul.submenu a:before {
          display: inline-block;
          margin-right: 5px;
          margin-left: -15px;
          width: 10px;
          height: inherit;
          content: "\00BB";
          color: inherit;
          font-family: 'icons';
          text-align: center;
          font-size: inherit;
          vertical-align: top; }
      #nav ul.submenu span.submenu.level-2 {
        background-color: #0062a1;
        display: block;
        position: absolute;
        width: 55px;
        height: 44px;
        right: 0;
        top: 0;
        border-left: 1px solid #0062a1; }
        #nav ul.submenu span.submenu.level-2.is-active {
          background-color: #006bb0; }
      #nav ul.submenu ul.submenu {
        background-color: #006bb0;
        border-radius: 0; }
        #nav ul.submenu ul.submenu li a {
          border-top: 1px dotted #00548A;
          padding-left: 40px;
          transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1); }
          #nav ul.submenu ul.submenu li a::before, #nav ul.submenu ul.submenu li a::after {
            display: none; }
          #nav ul.submenu ul.submenu li a:hover {
            padding-left: 45px; }
  #nav li {
    display: block;
    margin: 0;
    padding: 0;
    height: auto;
    overflow: hidden;
    border: 0;
    border-top: 1px dotted #006bb0;
    position: relative; }
    #nav li:first-child {
      border-top: 0; }
    #nav li.onmobile {
      display: block; }
    #nav li.language-select {
      padding: 12px 20px; }
  #nav a, #nav span.submenu {
    float: none;
    display: block;
    margin: 0;
    padding: 9px 20px;
    height: auto;
    color: #fff;
    line-height: 1.6em;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    border: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.17s linear 0s; }
    #nav a.login:before, #nav a.cart:before, #nav span.submenu.login:before, #nav span.submenu.cart:before {
      display: inline-block;
      margin-right: 5px;
      color: inherit;
      content: "\ea77";
      font-family: 'Material Icons Round';
      font-weight: normal;
      font-size: 110%;
      vertical-align: top;
      width: 23px;
      text-align: left; }
    #nav a.cart:before, #nav span.submenu.cart:before {
      content: "\e8cc";
      font-family: 'Material Icons Outlined'; }
  #nav span.submenu:after, #nav a.submenu:after {
    float: right;
    margin-left: 10px;
    width: 15px;
    height: inherit;
    content: "\e817";
    color: inherit;
    font-family: 'icons';
    text-align: center;
    font-size: 105%;
    vertical-align: top; }
  #nav span.submenu.is-active, #nav a.submenu.is-active {
    border-bottom: 1px dotted #006bb0; }
    #nav span.submenu.is-active + ul.submenu, #nav a.submenu.is-active + ul.submenu {
      max-height: 470px;
      overflow-y: auto; }
  #nav span.submenu.is-active:after, #nav a.submenu.is-active:after {
    content: "\e816"; }
  #nav a:hover, #nav a.is-active, #nav li:hover span.submenu, #nav li:hover a.submenu, #nav ul.submenu a:hover, #nav ul.submenu a.is-active {
    background: #006bb0; }

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px; }
    #menutoggle span {
      display: inline-block; }
    #menutoggle:before {
      margin-right: 10px; } }

@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  #menutoggle {
    display: none; }
  #nav {
    display: flex;
    height: 45px;
    background: #00548A;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px; }
    #nav ul {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      max-height: 100%;
      opacity: 1;
      margin: 0;
      position: relative;
      z-index: 10;
      top: 0;
      overflow: visible; }
      #nav ul.submenu {
        display: block;
        float: left;
        margin: 0;
        padding: 0;
        width: 300px;
        max-height: 0;
        position: absolute;
        opacity: 0;
        background-color: #006bb0;
        top: 95%;
        z-index: 10;
        border-radius: 0 3px 3px 3px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
        overflow: hidden;
        overflow-y: auto; }
        #nav ul.submenu.left {
          right: 0;
          left: auto !important;
          border-radius: 3px 0 3px 3px; }
        #nav ul.submenu li {
          float: none;
          display: block;
          margin: 0;
          padding: 0;
          border: 0;
          height: auto; }
          #nav ul.submenu li:first-child a {
            border: 0; }
        #nav ul.submenu a {
          float: none;
          display: block;
          margin: 0;
          padding: 7px 15px 7px 28px;
          height: auto;
          text-transform: none;
          text-align: left;
          line-height: 1.5em;
          border-radius: 0;
          border: 0;
          border-top: 1px dotted #00548A; }
        #nav ul.submenu span.submenu.level-2 {
          width: 50px;
          height: 38px;
          top: 1px;
          border-radius: 0 !important;
          border-bottom: 0;
          background-color: #0073bd; }
          #nav ul.submenu span.submenu.level-2:hover {
            background-color: #0062a1; }
          #nav ul.submenu span.submenu.level-2:after {
            width: 100%;
            display: block; }
          #nav ul.submenu span.submenu.level-2.is-active {
            background-color: #0062a1; }
            #nav ul.submenu span.submenu.level-2.is-active + ul.submenu {
              max-height: 300px !important;
              overflow-y: auto; }
        #nav ul.submenu ul.submenu {
          float: none;
          display: block;
          position: relative;
          padding: 0 !important;
          box-shadow: none;
          max-height: 0 !important;
          overflow: hidden; }
          #nav ul.submenu ul.submenu li {
            overflow: hidden; }
            #nav ul.submenu ul.submenu li a {
              border-radius: 0;
              border-top: 1px dotted #00548A;
              padding-left: 28px !important;
              background-color: #0062a1;
              transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1); }
              #nav ul.submenu ul.submenu li a:hover {
                padding-left: 33px !important;
                background-color: #00548A; }
    #nav li {
      flex-grow: 1;
      margin: 0;
      padding: 0;
      height: 100%;
      width: auto;
      min-width: auto;
      position: relative;
      border: 0;
      overflow: visible;
      border-right: 1px solid rgba(255, 255, 255, 0.3); }
      #nav li:first-child {
        margin-left: 0;
        border-left: 0;
        border-left: 1px solid rgba(255, 255, 255, 0.3); }
      #nav li.last {
        border-right: 0; }
      #nav li.right {
        float: right;
        margin-right: 0;
        border-right: 0; }
      #nav li.onmobile {
        display: none; }
      #nav li:hover {
        z-index: 11; }
    #nav a, #nav span.submenu {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0 10px;
      width: 100%;
      height: 100%;
      border: 0;
      color: #fff;
      font-weight: normal;
      line-height: 39px;
      text-align: center;
      transition: background 0.2s linear 0s;
      cursor: pointer; }
    #nav span.submenu:after, #nav a.submenu:after {
      display: none; }
    #nav a:hover, #nav a.is-active, #nav li:hover span.submenu, #nav li:hover a.submenu, #nav li:hover a.submenu, #nav ul.submenu a:hover, #nav span.submenu.is-active, #nav a.submenu.is-active {
      background: #006bb0; }
    #nav li:hover a.submenu, #nav li:hover span.submenu, #nav li:hover a.submenu, #nav span.submenu.is-active.hover, #nav a.submenu.is-active.hover {
      border-radius: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    #nav li:hover ul.submenu, #nav ul.submenu.is-active {
      padding: 3px 0;
      left: 0;
      max-height: 450px;
      opacity: 1; }
    #nav ul.submenu li a:hover, #nav ul.submenu li a.is-active {
      background: #00548A; } }

/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  line-height: 1.35;
  font-size: 21px;
  font-weight: normal;
  font-family: "Fjalla One", Impact, Verdana;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  outline: 0;
  overflow: hidden;
  transition: background-color 0.1s ease-in-out 0.05s;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0); }
  .cta-btn:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.3s, opacity 0.5s; }
  .cta-btn:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s; }

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c); }

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #434343;
  border: 0; }

.cta-btn.big {
  padding: 13px 0 10px 0; }

.cta-btn.big.outline {
  padding: 12px 0 9px 0; }

.cta-btn.small {
  padding: 9px 0 7px 0;
  font-size: 18px; }

.cta-btn.small.fancy {
  line-height: 41px; }

.cta-btn.small.outline {
  padding: 8px 0 6px 0; }

.cta-btn.dark {
  color: #fff;
  background: #555555; }

.cta-btn.orange {
  color: #fff;
  background: #FF6633; }

.cta-btn.yellow {
  color: #333;
  background: #ffc107; }

.cta-btn.green {
  color: #fff;
  background: #5CB865; }

.cta-btn.red {
  color: #fff;
  background: #f44336; }

.cta-btn.blue {
  color: #fff;
  background: #006BB0; }

.cta-btn[class~="outline"] {
  border: 1px solid #555555; }

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555; }

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555; }

.cta-btn.orange.outline {
  color: #FF6633;
  background: transparent;
  border-color: #FF6633; }

.cta-btn.green.outline {
  color: #5CB865;
  background: transparent;
  border-color: #5CB865; }

.cta-btn.red.outline {
  color: #f44336;
  background: transparent;
  border-color: #f44336; }

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107; }

.cta-btn.blue.outline {
  color: #006BB0;
  background: transparent;
  border-color: #006BB0; }

.cta-btn.pill {
  border-radius: 100px; }

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c); }

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #434343; }

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1); }

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #FF6633;
  background-image: linear-gradient(#ff855c, #ff531a); }

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #ff4b0f; }

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 102, 51, 0.1); }

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #5CB865;
  background-image: linear-gradient(#78c480, #4cae56);
  color: #fff; }

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #49a752; }

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: rgba(92, 184, 101, 0.1); }

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #f44336;
  background-image: linear-gradient(#f6675d, #f32c1e); }

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #f22314; }

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(244, 67, 54, 0.1); }

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #006BB0;
  background-image: linear-gradient(#0084d9, #005b97); }

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #0081d4; }

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: rgba(0, 107, 176, 0.1); }

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100); }

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b; }

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1); }

@keyframes btnProcessing {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.btn:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25); }

.btn.add-to-cart-btn {
  position: relative;
  transition: padding-left 0.3s, opacity 0.5s;
  font-weight: 600; }
  .btn.add-to-cart-btn + .price-display {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    font-weight: 600; }
  .btn.add-to-cart-btn.processing {
    padding-left: 2.5rem; }
    .btn.add-to-cart-btn.processing:before {
      display: block;
      position: absolute;
      content: "";
      top: 50%;
      left: 0.7rem;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      border-radius: 20px;
      border: 3px solid #fff;
      border-top-color: black;
      opacity: 0.5;
      animation: btnProcessing 1s linear infinite; }
    .btn.add-to-cart-btn.processing:active, .btn.add-to-cart-btn.processing:focus {
      box-shadow: none; }
  .btn.add-to-cart-btn.view-cart-btn {
    background-color: #1fbf66;
    border-color: #1fbf66; }
    .btn.add-to-cart-btn.view-cart-btn:before {
      display: inline-block;
      margin-right: 5px;
      width: inherit;
      height: inherit;
      content: "\e8cc";
      color: inherit;
      font-family: "Material Icons Outlined";
      font-weight: 400;
      text-align: center;
      font-size: 105%;
      vertical-align: top; }

.btn.btn-phone, .btn.btn-email {
  font-weight: 600;
  min-width: 200px;
  background-color: #006BB0;
  border: 3px solid #fff;
  color: #fff; }

.btn.btn-email {
  background-color: #fff;
  border: 3px solid #006BB0;
  color: #006BB0; }

.btn.btn-phone:hover {
  border: 3px solid #fff; }

.btn font {
  vertical-align: baseline !important;
  pointer-events: none; }

.btn-primary {
  background-color: #006BB0;
  border-color: #006BB0; }
  .btn-primary.btn-cta {
    font-weight: 600;
    min-width: 200px; }
    .btn-primary.btn-cta.btn-lg {
      min-width: 260px; }
  .btn-primary:hover {
    background-color: #007bca;
    border-color: #008ae3; }

.btn-outline-primary {
  border-color: #006BB0;
  color: #006BB0; }
  .btn-outline-primary:hover {
    background-color: #007bca;
    border-color: #008ae3; }

.btn-secondary {
  background-color: #00611C;
  border-color: #00611C; }
  .btn-secondary.btn-cta {
    font-weight: 600;
    min-width: 230px; }
    .btn-secondary.btn-cta-sm {
      min-width: 160px; }
  .btn-secondary:hover {
    background-color: #007b23;
    border-color: #00942b; }

.btn-outline-secondary {
  border-color: #00611C;
  color: #00611C; }
  .btn-outline-secondary:hover {
    background-color: #007b23;
    border-color: #00942b; }

.btn-danger {
  background-color: #880303;
  border-color: #880303; }

.dropdown-list.dropdown-cta {
  display: inline-block;
  width: auto; }
  .dropdown-list.dropdown-cta span.dropdown {
    padding: 0.5rem 1rem;
    display: inline-block;
    width: auto;
    height: auto;
    background-color: #006BB0;
    border-color: #006BB0;
    border-radius: 0.3rem;
    font-family: var(--font-family);
    line-height: 1.5;
    text-align: left; }
    .dropdown-list.dropdown-cta span.dropdown:after {
      font-weight: 400; }
    .dropdown-list.dropdown-cta span.dropdown:hover {
      background-color: #007bca;
      border-color: #008ae3; }
  .dropdown-list.dropdown-cta .drop {
    top: calc(100% - 3px); }

/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll; }

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555; }

.wrap {
  padding: 30px 0; }

.container-fluid.but-fixed {
  max-width: 1350px; }

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background-color: #006BB0;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 300; }
  .footer a,
  .footer h3,
  .footer h4 {
    color: #ffffff; }
  .footer .social a {
    border: 0; }

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none; }
  #header a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 100px; }
    #header a.logo img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100%; }
    #header a.logo span.big,
    #header a.logo span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle; }
    #header a.logo span.big {
      font-weight: 600;
      font-family: var(--font-family); }
    #header a.logo span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal; }
  #header #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto; }
    #header #support p {
      float: right;
      margin: 2px 12px 0 0; }
      #header #support p strong {
        font-size: 16px; }
    #header #support a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #780019;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0; }
      #header #support a.login:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\ea77";
        color: inherit;
        font-family: "Material Icons Round";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top; }
      #header #support a.login:hover {
        background: #9e0021; }
    #header #support a.cart {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #780019;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0; }
      #header #support a.cart:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\e8cc";
        color: inherit;
        font-family: "Material Icons Outlined";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top; }
      #header #support a.cart:hover {
        background: #9e0021; }
    #header #support span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333; }
    #header #support a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555; }
      #header #support a.livechat:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\e830";
        color: inherit;
        font-family: "icons";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top; }
    #header #support #language {
      margin-top: 20px;
      float: right;
      clear: right;
      width: 170px; }

/* ----------------------------------------------------------------------------
    Blogs styles
----------------------------------------------------------------------------- */
.post {
  padding: 20px 0;
  border-bottom: 1px solid #dee2e6;
  overflow: hidden; }
  .post p:last-child {
    margin-bottom: 0; }
  .post .post-img {
    max-width: 200px; }
  .post .post-details {
    overflow: hidden; }
  .post .published-date {
    opacity: 0.7;
    font-size: 0.9rem; }
  .post .more-link {
    display: none; }
    .post .more-link:hover {
      margin-bottom: -1px; }

.screen-reader-text {
  display: none; }

figure {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center; }
  figure img {
    width: 100%;
    height: auto;
    padding: 0.25rem;
    border-radius: 0.25rem;
    border: 1px solid #dee2e6; }
  figure figcaption {
    margin-top: 1rem; }
  figure.alignright {
    float: right;
    max-width: 40%;
    margin-left: 1.5rem; }
  figure.alignleft {
    float: left;
    max-width: 40%;
    margin-right: 1.5rem; }

/* ----------------------------------------------------------------------------
    global styles
----------------------------------------------------------------------------- */
#support-wrap {
  border-top: 1px solid #fff;
  background-color: #006BB0; }
  #support-wrap a {
    padding: 3px 0 3px 0;
    color: #fff;
    background-color: #780019;
    border: 0; }
    #support-wrap a:first-child {
      border-right: 1px solid #fff; }
    #support-wrap a:hover {
      background-color: #92001e; }
    #support-wrap a.login:before, #support-wrap a.cart:before {
      display: inline-block;
      margin-right: 5px;
      color: inherit;
      content: "\ea77";
      font-family: "Material Icons Round";
      font-weight: normal;
      font-size: 105%;
      vertical-align: top; }
    #support-wrap a.cart:before {
      content: "\e8cc";
      font-family: "Material Icons Outlined"; }

#banner-wrap h1,
#banner-wrap h2,
#banner-wrap h3 {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); }

#banner-wrap.home {
  background-image: linear-gradient(-10deg, #2b9dee 0%, #006BB0 100%); }

#banner-wrap.pst {
  background-color: #e0ece4; }
  #banner-wrap.pst h1,
  #banner-wrap.pst h2,
  #banner-wrap.pst h3 {
    text-shadow: none; }
  #banner-wrap.pst p {
    font-size: 1.2rem; }

#banner-wrap.faded-bg {
  position: relative; }
  #banner-wrap.faded-bg h1,
  #banner-wrap.faded-bg h2,
  #banner-wrap.faded-bg h3 {
    text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.5); }
  #banner-wrap.faded-bg:before {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0.8;
    background-color: #fff; }

#banner-wrap.pesticide {
  background: transparent url("/images/pesticide-applicator-ag-cover-image-sm.jpg") no-repeat 50% 50%/cover; }

#banner-wrap.affiliation {
  background: transparent url("/images/affiliations-header-bg-sm.jpg") no-repeat 50% 50%/cover; }

#banner-wrap.government {
  background: transparent url("/images/government-solution-bg.jpg") no-repeat 50% 80%/cover; }

#banner-wrap.wps, #banner-wrap.business {
  background: transparent url("/images/agriculture-pesticide-background-sm.jpg") no-repeat 50% 50%/cover; }

#banner-wrap.about-us, #banner-wrap.contact-us, #banner-wrap.work-with-us {
  position: relative; }
  #banner-wrap.about-us:before, #banner-wrap.about-us:after, #banner-wrap.contact-us:before, #banner-wrap.contact-us:after, #banner-wrap.work-with-us:before, #banner-wrap.work-with-us:after {
    content: "";
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    height: 50px;
    clip-path: polygon(50% 100%, 100% 0, 100% 100%, 0 100%, 0 0); }
  #banner-wrap.about-us:after, #banner-wrap.contact-us:after, #banner-wrap.work-with-us:after {
    background: #fff;
    opacity: 0.7;
    height: 80px; }

#phone-wrap {
  background-color: #1fbf66;
  color: #00532b; }
  #phone-wrap .open-phone {
    background-color: #015f32;
    color: #e3ffba;
    position: relative; }
    #phone-wrap .open-phone::before, #phone-wrap .open-phone::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border: 22px solid transparent;
      border-left: 25px solid #1fbf66;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
    #phone-wrap .open-phone::after {
      left: auto;
      right: 0;
      border: 22px solid transparent;
      border-right: 25px solid #1fbf66; }

.bg-light-blue {
  background-color: #e5eef3; }

.card.border-3 {
  border-width: 3px; }

.card.border-theme-primary {
  border-color: #006BB0; }

.card.website {
  border-color: #006BB0; }

.card.review {
  border-color: #006BB0; }
  .card.review .reviewer {
    text-align: center; }
    .card.review .reviewer:after {
      margin: 10px auto 0 auto;
      display: block;
      width: 60px;
      height: 60px;
      content: "\201D";
      font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 60px;
      line-height: 1.4;
      color: #006BB0;
      font-weight: 700;
      text-align: center;
      border: 3px solid #006BB0;
      border-radius: 50%; }

.card.about-us {
  border: 0;
  border-top: 5px solid #009afd;
  background-color: #006BB0; }
  .card.about-us .img-fluid {
    max-width: 220px; }

.card.contact-us {
  border-color: #006BB0; }
  .card.contact-us .img-fluid {
    max-width: 220px; }
  .card.contact-us a.phone-link {
    border-top: 3px solid #006BB0;
    color: #006BB0; }

.card.course-type {
  border-color: #006BB0;
  margin-top: 50px; }
  .card.course-type .icon {
    margin-top: -43px;
    margin-left: 1.25rem;
    padding: 15px;
    width: 86px;
    height: 86px;
    border: 3px solid #006BB0;
    color: #444;
    background-color: #fff;
    border-radius: 0.3rem;
    text-align: center; }

.staff {
  padding: 0 0.75rem; }
  .staff .img-fluid {
    max-width: 150px; }

.line-seperator {
  position: relative;
  margin-bottom: 2.75rem;
  background: #006BB0;
  width: 100%;
  height: 20px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); }

.boxed-hover {
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out; }
  .boxed-hover:hover {
    box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2); }

#other-solutions-wrap,
#wps-wrap,
.bg-light-green {
  background-color: #e5efe8; }

.card-header-wrapper {
  position: relative; }
  .card-header-wrapper .title-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }

.border-bottom-3 {
  border-bottom: 3px solid #00611C; }

.form-msg p:last-child {
  margin-bottom: 0; }

.notice-wrap {
  background-color: #dd3318;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 15px;
  position: relative;
  text-align: center;
  z-index: 5000000; }

.notice-wrap p {
  color: #e8f0fe;
  margin: 0 16px; }

.closeBtn {
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
  color: #fff;
  padding: 0 5px;
  font-weight: 400; }

.closeBtn:hover {
  color: #ccc; }

.card-cta a {
  min-width: 235px;
  padding: 0.5rem 1rem;
  width: auto;
  height: auto;
  background-color: #006BB0;
  border-color: #006BB0;
  border-radius: 0.3rem;
  font-family: var(--font-family);
  line-height: 1.5; }

.why-choose {
  background: linear-gradient(13deg, #9e4913 55%, #c05c1e 55%); }

.testimonial {
  border: 3px solid #00611C;
  border-radius: 5px; }
  .testimonial .card-body p {
    font-size: 1.2rem;
    line-height: 1.7rem; }
  .testimonial .card-body p span {
    background: #fff;
    line-height: 0.5rem;
    padding: 2.5rem 0.4rem 0.1rem;
    position: absolute;
    top: -30px;
    border: #00611C 3px solid;
    border-radius: 50%; }
    .testimonial .card-body p span::after {
      content: "\201D";
      color: #006BB0;
      font-family: Georgia, "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
      font-size: 5rem;
      font-weight: 700;
      position: relative;
      vertical-align: middle; }
  .testimonial .card-footer p {
    font-weight: 600;
    font-size: 1.1rem; }

.mobile-phone {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #008000;
  color: #fff !important;
  text-align: center;
  left: 20px;
  bottom: 20px;
  border-radius: 50px; }
  .mobile-phone .icon-phone {
    font-size: 1.75rem;
    line-height: 48px; }
    .mobile-phone .icon-phone:before {
      transform: rotate(-90deg); }

.phone-callout {
  background-color: #00611C; }
  .phone-callout p {
    font-size: 1.2rem; }
  .phone-callout a {
    border-bottom: 1px dotted #fff; }

#icons-wrap {
  border-bottom: 2px solid #ccc; }
  #icons-wrap .icon-item {
    border-bottom: 2px dotted #aaa; }
  #icons-wrap .icon-item:last-child {
    border-bottom: 0; }

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px; }
  #header a.logo {
    max-width: 150px; }
  #header-wrap #header #support {
    display: block; }
    #header-wrap #header #support span.phone {
      display: inline-block; }
  #phone-wrap {
    font-size: 1.2rem; }
  .closeBtn {
    right: 20px; }
  #icons-wrap {
    border-bottom: 0; }
    #icons-wrap .icon-item {
      border-bottom: 0; }
      #icons-wrap .icon-item:after {
        border-right: 2px dotted #aaa;
        content: "";
        position: absolute;
        right: 0;
        top: 15%;
        bottom: 15%;
        width: 2px; }
      #icons-wrap .icon-item:last-child:after {
        border-right: 0; } }

@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block; } }

@media (min-width: 992px) {
  #header a.logo {
    max-width: 200px; }
  #header #support {
    display: block; }
    #header #support span.phone {
      display: inline-block; }
  #phone-wrap {
    font-size: 1.3rem; }
    #phone-wrap .open-phone {
      font-size: 1.4rem;
      border-radius: 20px; }
      #phone-wrap .open-phone::before {
        left: 17px; }
      #phone-wrap .open-phone::after {
        right: 17px; }
    #phone-wrap .open-blurb {
      position: relative; }
      #phone-wrap .open-blurb::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 28px;
        background-color: #1fbf66;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1; }
    #phone-wrap .open-days::after {
      position: absolute;
      content: "";
      width: 20px;
      height: 28px;
      background-color: #1fbf66;
      left: -20px;
      top: 50%;
      transform: translateY(-50%); }
  #banner-wrap.pesticide {
    background: transparent url("/images/pesticide-applicator-ag-cover-image.jpg") no-repeat 50% 50%/cover; }
  #banner-wrap.affiliation {
    background: transparent url("/images/affiliations-header-bg.jpg") no-repeat 50% 50%/cover; }
  #banner-wrap.wps, #banner-wrap.business {
    background: transparent url("/images/agriculture-pesticide-background.jpg") no-repeat 50% 50%/cover; }
  #banner-wrap.pst p {
    font-size: 1.4rem; } }

@media (min-width: 1200px) {
  #icons-wrap {
    border-bottom: 0; }
    #icons-wrap .icon-item {
      border-bottom: 0;
      border-right: 2px dotted #aaa; }
      #icons-wrap .icon-item:after {
        border-right: 0; }
    #icons-wrap .icon-item:last-child {
      border-right: 0; } }
